/*
 * @Author: dongjia
 * @Date: 2021-08-13 14:16:38
 * @LastEditTime: 2021-08-13 18:20:18
 * @LastEditors: aleaner
 * @Description: 成员管理接口
 * @FilePath: \saas-admin-vue\src\modules\community-admin\api\members-admin.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

// 获取列表数据
export const memberList = data => {
  return api({
    url: "/admin/circle/member/index",
    method: "post",
    data
  });
};

// 新增成员
export const addMember = data => {
  return api({
    url: "/admin/circle/member/addMember",
    method: "post",
    data
  });
};

// 获取所属架构/职务列表
export const positionLevel = data => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data
  });
};

// 移除成员
export const Delete = data => {
  return api({
    url: "/admin/circle/member/delete",
    method: "post",
    data
  });
};

// 修改成员角色
export const changeRole = data => {
  return api({
    url: "/admin/circle/member/changeRole",
    method: "post",
    data
  });
};

// 拉黑、移出黑名单
export const changeBlack = data => {
  return api({
    url: "/admin/circle/member/changeBlack",
    method: "post",
    data
  });
};

// 通过审核
export const checked = data => {
  return api({
    url: "/admin/circle/member/check",
    method: "post",
    data
  });
};

// 不通过审核
export const uncheck = data => {
  return api({
    url: "/admin/circle/member/uncheck",
    method: "post",
    data
  });
};