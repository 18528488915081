<!--
 * @Author: dongjia
 * @Date: 2021-08-13 15:55:30
 * @LastEditTime: 2022-03-30 10:50:04
 * @LastEditors: aleaner
 * @Description: 修改成员角色
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\community-admin\components\group\ChangeRole.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="设置" :visible.sync="openDialog" width="500px" :show-close="true" class="dialog-vertical"
    @close="cancel('roleConfig')">
    <el-form size="medium" :model="roleData" :rules="rules" ref="roleConfig" label-width="80px">
      <el-form-item label="小组角色" prop="user_role">
        <el-select v-model="roleData.user_role" placeholder="请选择小组角色">
          <el-option v-for="item in roleOptions" :key="item.id" :label="item.name" :value="item.id"
            :disabled="[4,5].includes(item.id)">
          </el-option>
        </el-select>
        <div class="form-tip">
          <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
          <span>高级成员、资深成员不允许设置，需用户自己按等级权益来升级</span>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('roleConfig')">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { changeRole, checked } from "../../api/group/members-admin";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    Data: {
      type: Object,
    },
    // 弹窗功能类型
    handleType: {
      type: String,
      default: "change",
    },
    // 弹窗
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      rules: {
        user_role: [
          { required: true, message: "请选择小组角色", trigger: "change" },
        ],
      },
      roleOptions: [
        { id: 0, name: "普通成员" },
        { id: 4, name: "高级成员" },
        { id: 5, name: "资深成员" },
        { id: 3, name: "管理员" },
        { id: 2, name: "副组长" },
        { id: 1, name: "组长" },
      ],
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.handleType === "change") {
            changeRole(this.roleData)
              .then((res) => {
                this.$message.success(res.msg);
                this.updateList();
                this.$refs[formName].resetFields();
                this.openDialog = false;
              })
              .catch((err) => {});
          } else {
            checked(this.roleData)
              .then((res) => {
                this.$message.success(res.msg);
                this.updateList();
                this.$refs[formName].resetFields();
                this.openDialog = false;
              })
              .catch((err) => {});
          }
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    roleData: {
      get() {
        return this.Data;
      },
      set(val) {
        this.$emit("update: updateData", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
</style>