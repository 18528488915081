<!--
 * @Author: dongjia
 * @Date: 2021-08-13 13:47:31
 * @LastEditTime: 2022-03-30 17:41:46
 * @LastEditors: aleaner
 * @Description: 小组成员管理
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\community-admin\views\group\member-admin.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page">
    <el-button
      class="top-btn"
      type="primary"
      size="small"
      @click="handleAddMember"
      >新增
    </el-button>
    <list-filter :filterForm="filterForm" :uploadFilter="ok" />
    <list-tabs
      v-model="filterForm.tab"
      :tabs="tabArr"
      :isLoading="loading"
      @tab-click="handleClick"
    >
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn">
        <!-- <el-button type="warning" size="small" @click="handleJoin">加入组织</el-button> -->
        <el-button
          v-if="['checking', 'uncheck'].includes(filterForm.tab)"
          size="small"
          @click="handleChecked"
        >
          通过
        </el-button>
        <el-button
          v-if="filterForm.tab === 'checking'"
          size="small"
          @click="handleNoChecked"
        >
          不通过
        </el-button>
        <el-button
          v-if="filterForm.tab !== 'blacked'"
          type="danger"
          size="small"
          @click="handleDel"
          >移出
        </el-button>
        <el-button
          v-if="filterForm.tab === 'blacked'"
          type="success"
          size="small"
          @click="handleCancelblack"
        >
          恢复
        </el-button>
      </div>
      <el-table
        :data="tbody"
        class="thead-light"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column
          label="操作"
          :width="
            ['checked', 'uncheck', 'checking'].includes(filterForm.tab)
              ? '200'
              : '80'
          "
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button-group>
              <el-button
                v-if="!scope.row.black_status"
                :disabled="[1, 2, 3].includes(scope.row.user_role)"
                size="small"
                @click="blackRow(scope.row)"
                type="text"
              >
                拉黑
              </el-button>
              <el-button
                v-else
                size="small"
                @click="cancelblackRow(scope.row)"
                type="text"
              >
                移出黑名单
              </el-button>
              <el-button
                v-if="['checking', 'uncheck'].includes(filterForm.tab)"
                size="small"
                @click="checkedRow(scope.row)"
                type="text"
              >
                通过
              </el-button>
              <el-button
                v-if="filterForm.tab === 'checking'"
                size="small"
                @click="noCheckedRow(scope.row)"
                type="text"
              >
                不通过
              </el-button>
              <el-button
                v-if="filterForm.tab === 'checked'"
                size="small"
                @click="configRow(scope.row)"
                type="text"
              >
                设置
              </el-button>
              <el-button
                v-if="filterForm.tab !== 'blacked'"
                size="small"
                @click="deleteRow(scope.row)"
                type="text"
                >移出</el-button
              >
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead[theadKey]">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
            :sortable="th.sortable"
          >
            <template slot-scope="scope">
              <div class="black-member" v-if="th.prop === 'nickname'">
                <p>{{ scope.row[th.prop] | placeholder }}</p>
                <div class="black-tag" v-if="scope.row.black_status">
                  黑名单
                </div>
              </div>
              <div v-else-if="th.prop === 'posts'">
                {{ `${scope.row.posts_count}/${scope.row.complaints_count}` }}
              </div>
              <div v-else-if="th.prop === 'complaints'">
                {{
                  `${scope.row.comments_count}/${scope.row.comment_complaints_count}`
                }}
              </div>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
    <SelectMember
      v-model="openSelectMember"
      :Members="selectedMembers"
      @changeSelect="handleSelect"
    />
    <ChangeRole
      v-model="openChangeRole"
      :Data="roleConfig"
      :handleType="changeRowType"
      :updateList="refreshList"
    ></ChangeRole>
    <NoCheckedDialog
      v-model="openNoCheckedDialog"
      :Data="noCheckData"
      :updateList="refreshList"
    />
    <!-- 设置黑名单弹窗 -->
    <SetMemberBlackDialog
      v-model="showBlackDialog"
      :id="current_id"
      blackType="member"
      :updateList="refreshList"
    />

    <!-- 移出小组弹窗 -->
    <MoveOutGroupDialog
      v-model="showMoveOutDialog"
      :id="current_id"
      :updateList="refreshList"
    />
  </div>
</template>

<script>
import listFilter from "../../components/group/MembersFilter";
import Pagination from "@/base/components/Default/Pagination";
import ChangeRole from "../../components/group/ChangeRole";
import SelectMember from "@/modules/common/components/MemberSelector";
import NoCheckedDialog from "../../components/group/NoCheckedDialog";
import SetMemberBlackDialog from "../../components/SetMemberBlackDialog";
import MoveOutGroupDialog from "../../components/group/MoveOutGroupDialog";
import { memberList, addMember } from "../../api/group/members-admin";
import { setBlack } from "../../api/black-member.js";
export default {
  data() {
    return {
      tabArr: [
        { label: "已审核", name: "checked" },
        { label: "待审核", name: "checking", badge: 1 },
        { label: "未通过", name: "uncheck" },
        { label: "黑名单", name: "blacked" },
      ],
      loading: false, //加载
      thead: {
        checked: [
          { label: "名称", prop: "nickname", minWidth: 160 },
          { label: "职务", prop: "title_name", minWidth: 110 },
          {
            label: "小组角色",
            prop: "role_name",
            minWidth: 110,
          },
          {
            label: "帖子数",
            prop: "posts_count",
            minWidth: 100,
            sortable: true,
          },
          {
            label: "举报数",
            prop: "total_complaints",
            minWidth: 100,
            sortable: true,
          },
          {
            label: "加入时间",
            prop: "create_time",
            minWidth: 160,
            sortable: true,
          },
        ],
        unchecked: [
          { label: "名称", prop: "nickname", minWidth: 110 },
          { label: "职务", prop: "title_name", minWidth: 110 },
          {
            label: "帖子数",
            prop: "posts_count",
            minWidth: 100,
            sortable: true,
          },
          {
            label: "举报数",
            prop: "complaints_count",
            minWidth: 100,
            sortable: true,
          },
          {
            label: "申请时间",
            prop: "create_time",
            minWidth: 160,
            sortable: true,
          },
        ],
        blacked: [
          { label: "名称", prop: "nickname", minWidth: 110 },
          { label: "职务", prop: "title_name", minWidth: 110 },
          { label: "申请状态", prop: "check_text", minWidth: 110 },
          {
            label: "帖子数总数/被举报数",
            prop: "posts",
            minWidth: 180,
            sortable: true,
          },
          {
            label: "发布评论总数/被举报数",
            prop: "complaints",
            minWidth: 200,
            sortable: true,
          },
          {
            label: "拉黑时间",
            prop: "create_time",
            minWidth: 160,
            sortable: true,
          },
        ],
      },
      //筛选对象
      filterForm: {
        group_id: this.$route.params.id,
        create_start_time: -1, //申请开始时间
        create_end_time: -1, //申请结束时间
        join_start_time: -1,
        join_end_time: -1,
        min_posts: "",
        max_posts: "",
        user_role: -1, // 成员角色
        positionIds: [],
        name: "", // 名称
        tab: "checking", //当前筛选tab
        page_size: 15, //每页多少条数据
      },
      // 筛选缓存
      filters: {
        checked: null,
        checking: null,
        uncheck: null,
        blacked: null,
      },
      pageData: {
        page_size: 15,
        tab: "checking",
      },
      tbody: [],
      selectedMembers: [],
      selectArr: [], //勾选的数组id
      roleConfig: {},
      noCheckData: {},
      current_id: "",
      openChangeRole: false, // 开启设置弹窗
      openSelectMember: false,
      openNoCheckedDialog: false,
      showBlackDialog: false,
      showMoveOutDialog: false,
      changeRowType: "change",
    };
  },
  computed: {
    theadKey() {
      return ["checking", "uncheck"].includes(this.filterForm.tab)
        ? "unchecked"
        : this.filterForm.tab;
    },
  },
  methods: {
    // 获取品牌列表
    getMemberList(pageData) {
      this.tbody = [];
      this.pageData = {};
      this.loading = true;
      memberList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.selectedMembers = data.user_ids;
          // this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 新增成员
    handleAddMember() {
      this.openSelectMember = true;
    },
    // 获取选择会员
    handleSelect(e) {
      let user_id = [];
      e.forEach((item) => {
        if (
          this.selectedMembers.findIndex(
            (el) => el.user_id === item.user_id
          ) === -1
        ) {
          user_id.push(item.user_id);
        }
      });
      this.loading = true;
      addMember({ user_id, group_id: this.$route.params.id })
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.refreshList();
        });
    },
    // 批量移出
    handleDel() {
      if (this.selectArr.length) {
        this.showMoveOutDialog = true;
        this.current_id = this.selectArr;
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表移出
    deleteRow(row) {
      this.showMoveOutDialog = true;
      this.current_id = row.id;
    },
    // 拉黑数据
    blackRow(row) {
      this.current_id = row.id;
      this.showBlackDialog = true;
    },
    // 取消拉黑
    cancelblackRow(row) {
      this.loading = true;
      setBlack({ id: row.id, is_black: 0 }, "member")
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.refreshList();
        });
    },
    // 批量移出
    handleCancelblack() {
      if (this.selectArr.length) {
        this.loading = true;
        setBlack({ id: this.selectArr, is_black: 0 }, "member")
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch((err) => {
            this.refreshList();
          });
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击通过
    checkedRow(row) {
      this.changeRowType = "checked";
      this.roleConfig = {
        id: row.id,
        user_role: "",
      };
      this.openChangeRole = true;
    },
    // 批量通过
    handleChecked() {
      if (this.selectArr.length) {
        this.changeRowType = "checked";
        this.roleConfig = {
          id: this.selectArr,
          user_role: "",
        };
        this.openChangeRole = true;
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击不通过
    noCheckedRow(row) {
      this.noCheckData = {
        id: row.id,
        reason: "",
      };
      this.openNoCheckedDialog = true;
    },
    // 批量不通过
    handleNoChecked() {
      if (this.selectArr.length) {
        this.noCheckData = {
          id: this.selectArr,
          reason: "",
        };
        this.openNoCheckedDialog = true;
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击编辑
    editRow(row) {
      this.$router.push({
        name: "groupDetail",
        params: { id: row.id },
      });
    },
    // 点击设置
    configRow(row) {
      this.changeRowType = "change";
      this.roleConfig = {
        id: row.id,
        user_role: row.user_role,
      };
      this.openChangeRole = true;
    },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          group_id: this.$route.params.id,
          create_start_time: -1, //申请开始时间
          create_end_time: -1, //申请结束时间
          join_start_time: -1,
          join_end_time: -1,
          min_posts: "",
          max_posts: "",
          user_role: -1, // 成员角色
          positionIds: [],
          author_name: "", // 创建者名称
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        };
      }
      this.getMemberList(this.filterForm);
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getMemberList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getMemberList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    refreshList() {
      this.getMemberList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      const data = {};
      data.id = e.id;
      data[key] = e[key];
      this.loading = true;
      if (key == "is_recommend") {
        // 更新推荐状态
        updateRecommend(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      } else if (key === "is_show") {
        // 更新显示状态
        updateShow(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      } else {
        // 更新排序状态
        updateSort(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch(() => {
            this.refreshList();
          });
      }
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getMemberList(pageData);
    },
  },
  created() {
    // this.getPidList(); //获取上级组织列表
    this.getMemberList(this.filterForm); //获取列表数据
  },
  components: {
    listFilter,
    Pagination,
    ChangeRole,
    SelectMember,
    NoCheckedDialog,
    SetMemberBlackDialog,
    MoveOutGroupDialog,
  },
};
</script>

<style lang="scss" scoped>
.container {
  .body {
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }
  }
}
.list-icon {
  width: 60px;
  height: 60px;
}
.black-member {
  display: flex;
  align-items: center;
  .black-tag {
    margin-left: 10px;
    width: 44px;
    height: 28px;
    background-color: rgb(85, 85, 85);
    font-size: 12px;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
