<!--
 * @Author: dongjia
 * @Date: 2021-08-13 13:58:01
 * @LastEditTime: 2022-04-18 14:45:22
 * @LastEditors: aleaner
 * @Description: 成员管理筛选框
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\community-admin\components\group\MembersFilter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="true">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="用户名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入用户名称"></el-input>
        </el-form-item>
        <el-form-item label="小组角色：" prop="user_role">
          <el-select v-model="form.user_role" placeholder="请选择小组角色">
            <el-option v-for="item in roleOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="filter-item">
        <el-form-item label="用户职务：" prop="positionIds">
          <el-cascader v-model="form.positionIds" :options="parentPositionList" :props="props"
            placeholder="请选择">
          </el-cascader>
        </el-form-item>
        <el-form-item label="帖子数：" prop="">
          <div class="container">
            <el-form-item label-width="0px" prop="min_posts">
              <el-input v-model.number="form.min_posts"></el-input>
            </el-form-item>
            <div style="margin: 0 10px">-</div>
            <el-form-item label-width="0px" prop="max_posts">
              <el-input v-model.number="form.max_posts"></el-input>
            </el-form-item>
          </div>
        </el-form-item>
      </div>

      <div class="filter-item">
        <DatePeriodPicker label="申请时间：" start-prop="create_start_time"
          :start-time.sync="form.create_start_time" end-prop="create_end_time"
          :end-time.sync="form.create_end_time" />
      </div>

      <div class="filter-item">
        <DatePeriodPicker label="加入时间：" start-prop="join_start_time" :start-time.sync="form.join_start_time"
          end-prop="join_end_time" :end-time.sync="form.join_end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { positionLevel } from "../../api/group/members-admin";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      roleOptions: [
        { id: -1, name: "全部" },
        { id: 0, name: "普通成员" },
        { id: 4, name: "高级成员" },
        { id: 5, name: "资深成员" },
        { id: 3, name: "管理员" },
        { id: 2, name: "副组长" },
        { id: 1, name: "组长" },
      ],
      parentPositionList: [],
      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    positionLevel({ is_list_tree: 0, is_show_all: 1 })
      .then((res) => {
        this.parentPositionList = res.data;
      })
      .catch(() => {});
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  ::v-deep.el-input__inner {
    width: 67px !important;
  }
}
</style>